import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "../../components/Landers/landers.module.scss";
import { br } from "./SectionTemplate";

let settings = {
  autoplay: true,
  infinite: true,
  dots: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 3,
  prevArrow: (
    <img data-src="https://assets-sv-in.gumlet.io/LP/Staffing/arrow-left.svg" />
  ),
  nextArrow: (
    <img data-src="https://assets-sv-in.gumlet.io/LP/Staffing/arrow-right.svg" />
  ),
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default ({ h2, solutions, path }) => {
  const cards = solutions.map(({ p, h5 }, i) => (
    <div className={styles.card} key={i}>
      <div className={styles.img}>
        <img
          className={styles.quote}
          data-src={`https://assets-sv-in.gumlet.io/LP/${path}/Solutions/${
            i + 1
          }.svg`}
        />
      </div>
      <div className={styles.text}>
        <h5>{h5}</h5>
        <p>{p}</p>
      </div>
    </div>
  ));

  return (
    <section className={styles.customized}>
      <h2>{br(h2)}</h2>
      <Slider {...settings}>{cards}</Slider>
    </section>
  );
};
