import React from "react";
import AllCheck from "../../components/Landers/AllCheck";
import DataInfo from "../../components/Data";
import Banner from "../../components/Landers/Banner";
import Brands from "../../components/Landers/Brands";
import CustomizedSolutions from "../../components/Landers/CustomizedSolutions";
import Footer from "../../components/Landers/Footer";
import GetStarted from "../../components/Landers/GetStarted";
import Header from "../../components/Landers/Header";
import IsMobile from "../../components/Landers/IsMobile";
import styles from "../../components/Landers/landers.module.scss";
import SectionTemplate from "../../components/Landers/SectionTemplate";
import SEO from "../../components/Landers/SEO";
import Testimonials from "../../components/Landers/Testimonials";

const solutions3 = [
  {
    h5: "Seamless Candidate Experience for better Conversions",
    p:
      "With SpringVerify’s mobile-optimized dashboard, candidates can track the status of their background checks in real-time and connect with a live support team to get their queries resolved.",
  },
  {
    h5: "Enhanced Workflow Automation",
    p:
      "SpringVerify integrates with your exisiting HR system to automate and streamline their hiring process end to end. This can save you a lot of time, resource and manual effort. ",
  },
  {
    h5: "Reliable HR Systems and Tools",
    p:
      "When the demand is at large volumes due to seasonal spikes, HR managers can make instant hiring decisions saving time and scale their team effortlessly.",
  },
];

const testimonials = [
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. 
    The UX is awesome, checks are fast and most importantly all my queries are answered promptly without me having to wait for long.`,
  },
  {
    name: "Nandita N",
    company: "Umlaut Pvt. Ltd.",
    p: `SpringVerify is a true all-rounder when it comes to background verifications. Most services I've used in the past have always had compromises. But SpringVerify has been exceptional and impressive - right from the variety of checks it offers, its reliablity to 24x7 customer support. `,
  },
  {
    name: "Purushottam N",
    company: "EZOPS Technologies India Pvt. Ltd.",
    p: `No doubt SpringVerify has transformed the way we run background checks. I love the variety of checks it offers
			that delivers accurate results in a quick and efficient manner.`,
  },
];

export default () => (
  <div className={`${styles.Operation} ${styles.m0}`}>
    <SEO
      title="Background Screening Service for Operation Teams | SpringVerify India"
      desc="SpringVerify's purpose built technology caters Operations leaders to streamline hiring processes while reducing costs."
      url="https://in.springverify.com/operations/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/roles/SV-IN+(Operations).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Background Screening \nSolutions for \nOperation Leaders"}
        p="SpringVerify’s advanced background screening solution helps Operation teams like yours streamline the verification process with reduced cost and effort."
        img="https://assets-sv-in.gumlet.io/LP/Operations/banner.png"
      />
    </div>
    <Brands
      list={["flipkart", "inshorts", "rently", "airmeet", "simpl", "meesho"]}
    />
    <section>
      <SectionTemplate
        img="Operations/need.svg"
        h2="Challenges faced by Operations Teams"
        p={`Operations leaders in a company help ensure that hiring takes place seamlessly, from interviewing candidates to a delightful onboarding. Here are the key areas where Operations leaders feel the most challenged,`}
        ul={[
          "Complex IT solutions and integrations deliver a bad user experience",
          "Hiring at scale due to seasonal changes and market demand",
          "Legacy background checks are time-consuming and resource intensive",
          "Candidate conversion is heavily dependent on hiring speed and accuracy",
        ]}
      />
    </section>
    <AllCheck data={DataInfo.OperationAllCheck} />

    <section className={styles.OperationTemp}>
      <SectionTemplate
        img="Operations/how.svg"
        h2={"SpringVerify - Robust \nBackground Screening Solutions"}
        p={`From automating hiring workflows to running faster background checks with the best-in-class TAT, SpringVerify does it all with ease, Here's how: `}
        ul={[
          "20+ checks in one place",
          "Seamless integration with your HRMS and AITS systems",
          "Supports bulk and seasonal hiring",
          "Set up time in hours, not days",
          "Transparent and flexible pricing",
        ]}
      />
    </section>
    <CustomizedSolutions
      h2={"Customized Solutions for \nOperations Teams"}
      solutions={solutions3}
      path="Operations"
    />
    <div className={styles.bg}>
      <section className={styles.explore}>
        <h2>
          Explore more Industry & {!IsMobile() && <br />}
          Role-based Solutions
        </h2>
        <div className={styles.list}>
          {[
            ["/industry/fintech/", "Fintech"],
            ["/industry/e-commerce/", "E-commerce"],
            ["/industry/staffing-agencies", "Staffing"],
            ["/industry/technology", "IT and SaaS"],
            ["/industry/healthcare", "Healthcare"],
            ["/human-resources/", "HR"],
            ["/operations/", "Operations"],
            ["/compliance/", "Compliance"],
          ].map((e, i) => (
            <div>
              <a href={e[0]}>
                <img
                  key={i}
                  data-src={`https://assets-sv-in.gumlet.io/LP/Operations/Explore/${
                    i + 1
                  }.svg`}
                  alt={e[1]}
                />
                <h6>{e[1]}</h6>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="Operations/get-started.svg"
      h3="Start screening your candidates with SpringVerify"
    />
    <Footer />
  </div>
);
